var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-12 dashboard-header-announcement"},[(_vm.published.length !== 0)?_c('r-card',{staticClass:"dashboard-header-announcement__card"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left rise-card-title"},[_vm._v("Announcement")]),_c('router-link',{staticClass:"is-link",attrs:{"to":"announcement"}},[_vm._v("See All")])],1),_c('b-carousel',{attrs:{"interval":_vm.interval,"pause-info":_vm.pauseInfo}},_vm._l((_vm.published),function(carousel,i){return _c('b-carousel-item',{key:i,on:{"click":function($event){return _vm.announcementClick(carousel.id)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-3 pr-0 mr-0 rise-picture-container"},[(carousel.files && carousel.files.length !== 0)?_c('img',{staticClass:"click",attrs:{"src":_vm.getImageURL(carousel.files[0].url)}}):_c('img',{staticClass:"click",attrs:{"src":"/images/rise_default_announcement_pic.png"}})]),_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"column is-12 p-0 m-0"},[_vm._v(" "+_vm._s(carousel.title)+" ")]),_c('div',{staticClass:"column is-12 p-0 m-0 rise-announcement-content has-text-justified"},[_vm._v(" "+_vm._s(_vm.textSubString(carousel.content, 120, '...'))+" ")]),_c('div',{staticClass:"columns is-multiline pt-3"},[_c('div',{staticClass:"column is-1 pr-0 mr-0 rise-profile-picture-container"},[(
                    carousel.createdBy &&
                    carousel.createdBy.profilePictureUrl !== null
                  )?_c('img',{attrs:{"src":carousel.createdBy.profilePictureUrl}}):_c('img',{attrs:{"src":"/images/default-profile-picture-male.png"}})]),_c('div',{staticClass:"column is-11 pl-0 ml-0"},[_c('div',{staticClass:"column is-12 pt-0 pb-0 rise-announcement-content"},[_vm._v(" "+_vm._s(carousel.createdBy.fullName)+" ")]),_c('div',{staticClass:"column is-12 pt-0 pb-0 rise-announcement-date"},[_vm._v(" "+_vm._s(_vm.formatAnnouncementDate(carousel.updatedAt))+" ")])])])])])])}),1)],1):_c('r-card',{staticClass:"dashboard-header-announcement__card"},[_c('div',{staticClass:"container has-text-centered"},[_c('img',{attrs:{"src":"/images/no-announcement.png"}})]),_c('div',{staticClass:"container has-text-centered"},[_vm._v("No Announcement Available")])]),(_vm.isOpenModalAnnouncementDetail)?_c('r-page-modal',{key:"modalKey2",staticClass:"modal-rise-announcement",attrs:{"prompt-on-leave":false,"prompt-message":"Are you sure?","is-modal":true,"loading":_vm.isAnnouncementDetailLoading},on:{"hide":_vm.closeModalAnnouncementDetail},scopedSlots:_vm._u([{key:"page-content",fn:function(){return [_c('h3',{staticClass:"modal-title has-text-weight-black"},[_vm._v(" "+_vm._s(_vm.announcementDetail ? _vm.announcementDetail.title : '')+" "),_c('span',{staticClass:"office-pointer-click is-pulled-right"},[_c('b-icon',{attrs:{"icon":"close","size":"is-small"},nativeOn:{"click":function($event){return _vm.closeModalAnnouncementDetail($event)}}})],1)]),_c('div',{staticClass:"columns is-multiline pt-3"},[_c('div',{staticClass:"column is-1 pr-0 mr-0 rise-profile-picture-container"},[(
              _vm.announcementDetail &&
              _vm.announcementDetail.createdBy &&
              _vm.announcementDetail.profilePictureUrl !== null
            )?_c('img',{attrs:{"src":_vm.announcementDetail.createdBy.profilePictureUrl}}):_c('img',{attrs:{"src":"/images/default-profile-picture-male.png"}})]),_c('div',{staticClass:"column is-11 pl-0 ml-0"},[_c('div',{staticClass:"column is-12 pt-0 pb-0 rise-announcement-content"},[_vm._v(" "+_vm._s(_vm.announcementDetail ? _vm.announcementDetail.createdBy.fullName : '')+" ")]),_c('div',{staticClass:"column is-12 pt-0 pb-0 rise-announcement-date"},[_vm._v(" "+_vm._s(_vm.announcementDetail ? _vm.formatAnnouncementDate(_vm.announcementDetail.updatedAt) : '')+" ")])]),_c('div',{staticClass:"column is-12 mt-0 mb-0 pt-0"},[_c('hr',{staticClass:"black-divider"})]),(
            _vm.announcementDetail &&
            _vm.announcementDetail.files &&
            _vm.announcementDetail.files.length !== 0
          )?_c('div',{staticClass:"column is-12 rise-announcement-picture-container ml-3"},[_c('img',{attrs:{"src":_vm.getImageURL(_vm.announcementDetail.files[0].url)}})]):_vm._e(),_c('div',{staticClass:"column is-12 has-text-justified"},[_vm._v(" "+_vm._s(_vm.announcementDetail ? _vm.announcementDetail.content : '')+" ")])])]},proxy:true}],null,false,3723777824)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }