<template>
  <r-card
    class="primary-rise-card dashboard-header p-0 rise-quote-card"
    v-bind:style="{ 'background-image': 'url(' + getBackgroundImage() + ')' }"
  >
    <div class="columns is-vcentered dashboard-header__card">
      <div class="column">
        <h1 class="is-size-5" v-bind:style="{ color: getTextColor() }">
          Good {{ getTimeOfDay(timeOfDay) }}, {{ user && user.firstName }}
        </h1>
        <p
          class="is-size-6 is-narrow primary-card-work-status-detail"
          v-bind:style="{ color: getTextColor() }"
        >
          {{ quotes ? quotes.quote : '-' }}
        </p>
      </div>
    </div>
  </r-card>
</template>
<script>
import moment from 'moment-timezone'

export default {
  props: ['user', 'quotes'],
  data() {
    return {
      timeOfDay: Date.now(),
      image: null,
    }
  },

  methods: {
    formatTime(time) {
      return moment.utc(time).local().format('HH:mm')
    },
    getTimeOfDay(time) {
      if (moment.utc(time).local().format('HH') >= 18) {
        return 'Evening'
      } else if (moment.utc(time).local().format('HH') < 12) {
        return 'Morning'
      } else {
        return 'Afternoon'
      }
    },
    getBackgroundImage() {
      return moment.utc(this.timeOfDay).local().format('HH') < 12
        ? '/images/bg_rise_home.jpg'
        : '/images/bg_rise_home_2.jpg'
    },

    getTextColor() {
      return moment.utc(this.timeOfDay).local().format('HH') < 12
        ? '#3e89c4'
        : '#fff'
    },
  },

  mounted() {},
}
</script>
