<template>
  <div class="is-12 dashboard-header-announcement">
    <r-card
      v-if="published.length !== 0"
      class="dashboard-header-announcement__card"
    >
      <div class="level">
        <div class="level-left rise-card-title">Announcement</div>
        <router-link to="announcement" class="is-link">See All</router-link>
      </div>
      <b-carousel :interval="interval" :pause-info="pauseInfo">
        <b-carousel-item
          v-for="(carousel, i) in published"
          :key="i"
          @click="announcementClick(carousel.id)"
        >
          <div class="columns is-multiline">
            <div class="column is-3 pr-0 mr-0 rise-picture-container">
              <img
                v-if="carousel.files && carousel.files.length !== 0"
                :src="getImageURL(carousel.files[0].url)"
                class="click"
              />
              <img
                v-else
                src="/images/rise_default_announcement_pic.png"
                class="click"
              />
            </div>
            <div class="column is-9">
              <div class="column is-12 p-0 m-0">
                {{ carousel.title }}
              </div>
              <div
                class="column is-12 p-0 m-0 rise-announcement-content has-text-justified"
              >
                <!-- {{ textSubString(carousel.content, 80, '...') }} -->
                {{ textSubString(carousel.content, 120, '...') }}
              </div>
              <div class="columns is-multiline pt-3">
                <div
                  class="column is-1 pr-0 mr-0 rise-profile-picture-container"
                >
                  <img
                    v-if="
                      carousel.createdBy &&
                      carousel.createdBy.profilePictureUrl !== null
                    "
                    :src="carousel.createdBy.profilePictureUrl"
                  />
                  <img v-else src="/images/default-profile-picture-male.png" />
                </div>
                <div class="column is-11 pl-0 ml-0">
                  <div class="column is-12 pt-0 pb-0 rise-announcement-content">
                    {{ carousel.createdBy.fullName }}
                  </div>
                  <div class="column is-12 pt-0 pb-0 rise-announcement-date">
                    {{ formatAnnouncementDate(carousel.updatedAt) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-carousel-item>
      </b-carousel>
    </r-card>
    <r-card v-else class="dashboard-header-announcement__card">
      <div class="container has-text-centered">
        <img src="/images/no-announcement.png" />
      </div>
      <div class="container has-text-centered">No Announcement Available</div>
    </r-card>
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are you sure?"
      v-if="isOpenModalAnnouncementDetail"
      @hide="closeModalAnnouncementDetail"
      :is-modal="true"
      key="modalKey2"
      class="modal-rise-announcement"
      :loading="isAnnouncementDetailLoading"
    >
      <template #page-content>
        <h3 class="modal-title has-text-weight-black">
          {{ announcementDetail ? announcementDetail.title : '' }}
          <span class="office-pointer-click is-pulled-right">
            <b-icon
              icon="close"
              size="is-small"
              @click.native="closeModalAnnouncementDetail"
            ></b-icon>
          </span>
        </h3>
        <div class="columns is-multiline pt-3">
          <div class="column is-1 pr-0 mr-0 rise-profile-picture-container">
            <img
              v-if="
                announcementDetail &&
                announcementDetail.createdBy &&
                announcementDetail.profilePictureUrl !== null
              "
              :src="announcementDetail.createdBy.profilePictureUrl"
            />
            <img v-else src="/images/default-profile-picture-male.png" />
          </div>
          <div class="column is-11 pl-0 ml-0">
            <div class="column is-12 pt-0 pb-0 rise-announcement-content">
              {{
                announcementDetail ? announcementDetail.createdBy.fullName : ''
              }}
            </div>
            <div class="column is-12 pt-0 pb-0 rise-announcement-date">
              {{
                announcementDetail
                  ? formatAnnouncementDate(announcementDetail.updatedAt)
                  : ''
              }}
            </div>
          </div>
          <div class="column is-12 mt-0 mb-0 pt-0">
            <hr class="black-divider" />
          </div>
          <div
            class="column is-12 rise-announcement-picture-container ml-3"
            v-if="
              announcementDetail &&
              announcementDetail.files &&
              announcementDetail.files.length !== 0
            "
          >
            <img :src="getImageURL(announcementDetail.files[0].url)" />
          </div>
          <div class="column is-12 has-text-justified">
            {{ announcementDetail ? announcementDetail.content : '' }}
          </div>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import { mapActions } from 'vuex'
export default {
  props: ['published'],
  data() {
    return {
      interval: 5000,
      isOpenModalAnnouncementDetail: false,
      isAnnouncementDetailLoading: false,
      announcementDetail: null,
      pauseInfo: false,
    }
  },

  methods: {
    ...mapActions({
      fetchAnnouncementDetail: 'announcement/fetchAnnouncementDetail',
    }),

    getImageURL(item) {
      return item ? process.env.VUE_APP_API_URL + '/' + item : null
    },

    formatAnnouncementDate(str) {
      if (str) {
        return moment(str).format('MMMM DD, YYYY')
      }
    },

    async loadAnnouncementDetail(id) {
      try {
        const response = await this.fetchAnnouncementDetail({
          selectedAnnouncement: id,
        })
        this.announcementDetail = response.data.data
      } catch (e) {
        console.log(e)
      }
    },

    async announcementClick(id) {
      this.isAnnouncementDetailLoading = true
      this.isOpenModalAnnouncementDetail = true
      await this.loadAnnouncementDetail(id)
      this.isAnnouncementDetailLoading = false
    },

    closeModalAnnouncementDetail() {
      this.isOpenModalAnnouncementDetail = false
    },
  },
}
</script>
