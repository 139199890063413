<template>
  <div class="container has-background-white has-text-centered p-4">
    <slot name="after-capture" v-if="isCaptureFinished">
      <CaptureFinished
        :img-src="imageData.image"
        @close="close"
        :show-button-done="showButtonCaptureFinished"
      />
    </slot>
    <div class="columns is-multiline is-mobile" style="width: 640px" v-else>
      <div class="column is-12">
        <div
          v-if="!imageData.image"
          class="is-flex is-justify-content-center is-align-items-center"
        >
          <video
            v-if="!imageData.image"
            ref="video"
            class="camera-stream"
            id="video"
            width="640"
            height="480"
          />
          <canvas
            ref="canvas"
            id="canvas"
            style="width: 400px; height: 400px"
          ></canvas>
        </div>
        <img v-else :src="imageData.image" ref="capturedImage" />
      </div>
      <div class="column is-12" v-if="!isLoadingCamera">
        <div class="columns is-mobile is-multiline">
          <div class="column is-6-desktop" v-if="!imageData.image">
            <p>Please look to camera for a few seconds</p>
          </div>
          <div
            :class="
              !imageData.image
                ? 'column is-6-desktop'
                : 'column is-12-desktop has-text-centered'
            "
          >
            <b-button
              icon-left="close"
              @click.native="close"
              type="is-primary"
              outlined
              size="is-small"
              class="p-0"
              :disabled="submitLoading"
            >
              Cancel
            </b-button>
            <b-button
              icon-left="camera"
              @click.native="captureImage"
              v-if="!imageData.image"
              size="is-small"
            >
              Capture
            </b-button>

            <b-button
              icon-left="reload"
              @click.native="cancelImage"
              v-if="imageData.image"
              size="is-small"
              :disabled="submitLoading"
            >
              Retake
            </b-button>

            <b-button
              icon-left="upload"
              @click.native="submit"
              v-if="imageData.image"
              size="is-small"
              type="is-success"
              :loading="submitLoading"
            >
              Submit
            </b-button>
          </div>
        </div>
      </div>
      <div v-else class="column is-12 has-text-centered">
        <p>Loading camera...</p>
      </div>
    </div>
  </div>
</template>

<script>
import CaptureFinished from './CaptureFinished'
import * as faceapi from 'face-api.js'
export default {
  components: { CaptureFinished },
  props: {
    submitLoading: Boolean, // state to trigger loading in submit button
    isCaptureFinished: Boolean,
    isCloseFaceCam: Boolean,
    showButtonCaptureFinished: Boolean,
  },
  watch: {
    isCloseFaceCam(val) {
      if (val) {
        this.close()
      }
    },
  },
  data() {
    return {
      // url/path to image

      mediaStream: null,
      imageData: {
        image: '',
      },
      isLoadingCamera: false,
      listenerIntervalVideo: null,
    }
  },
  methods: {
    async submit() {
      try {
        let extractedImage = await this.extractFace()
        if (extractedImage) {
          this.imageData.image = extractedImage
        }
        this.$emit('submit', this.imageData.image)
      } catch (err) {
        this.alertCustomError(
          'Cannot detect face',
          'Face not detected. Please try again'
        )
        this.close()
      }
    },
    close() {
      if (this.mediaStream) {
        this.mediaStream.getTracks()[0].stop()
        this.mediaStream = null
      }
      this.$emit('close')
    },
    async extractFace() {
      //consider that we have image captured from imageData.image
      const detection = await faceapi.detectSingleFace(
        this.$refs.capturedImage,
        new faceapi.TinyFaceDetectorOptions()
      )
      if (detection == null) {
        throw new Error('Cannot detect face')
      }

      let box = detection.box

      // extract form box
      const regionsToExtract = [
        new faceapi.Rect(box.x, box.y, box.width, box.height),
      ]

      let faceImages = await faceapi.extractFaces(
        this.$refs.capturedImage,
        regionsToExtract
      )

      if (faceImages.length === 0) {
        return null
      } else {
        const resizeWidth = 112
        const resizeHeight = 112
        faceImages[0].style.height = resizeWidth
        faceImages[0].style.width = resizeHeight
        let tes = null
        var res = document.createElement('canvas')
        res.width = resizeWidth
        res.height = resizeHeight
        let res_con = res.getContext('2d')
        res_con.drawImage(
          faceImages[0],
          0,
          0,
          faceImages[0].width,
          faceImages[0].height,
          0,
          0,
          resizeWidth,
          resizeHeight
        )

        tes = res
        return tes.toDataURL()
      }
    },
    async captureImage() {
      let blob = await this.getBlobFromMediaStream(this.mediaStream)
      let reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = () => {
        this.imageData.image = reader.result
      }
      // stop
      this.stopVideo()
    },
    async getBlobFromMediaStream(stream) {
      if ('ImageCapture' in window) {
        console.log('image capture available')
        const videoTrack = stream.getVideoTracks()[0]
        const imageCapture = new ImageCapture(videoTrack)
        return imageCapture.takePhoto()
      } else {
        console.log('image capture no tavailable')
        const video = document.createElement('video')
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        video.srcObject = stream

        return new Promise((resolve, reject) => {
          video.addEventListener('loadeddata', async () => {
            const { videoWidth, videoHeight } = video
            canvas.width = videoWidth
            canvas.height = videoHeight

            try {
              await video.play()
              context.drawImage(video, 0, 0, videoWidth, videoHeight)
              canvas.toBlob(resolve, 'image/png')
            } catch (error) {
              reject(error)
            }
          })
        })
      }
    },
    stopVideo() {
      const tracks = this.mediaStream.getTracks()
      if (tracks.length > 0) {
        tracks[0].stop()
        this.$refs.video.pause()
        clearInterval(this.listenerIntervalVideo)
      }
      this.mediaStream = null
    },
    async playVideo() {
      try {
        const vgaConstraints = {
          video: { width: { exact: 640 }, height: { exact: 480 } },
        }
        let mediaStream = await navigator.mediaDevices.getUserMedia(
          vgaConstraints
        )

        this.$refs.video.srcObject = mediaStream
        this.$refs.video.play()
        this.mediaStream = mediaStream
        this.isLoadingCamera = false
        this.$refs.video.play()
        const video = document.getElementById('video')
        const canvas = document.getElementById('canvas')
        const displaySize = { width: video.width, height: video.height }

        let that = this
        video.addEventListener('play', () => {
          faceapi.matchDimensions(canvas, displaySize)
          that.listenerIntervalVideo = setInterval(async () => {
            const detection = await faceapi
              .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
              .withFaceExpressions()

            const resizedDetection = faceapi.resizeResults(
              detection,
              displaySize
            )
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
            faceapi.draw.drawDetections(canvas, resizedDetection)
            faceapi.draw.drawFaceExpressions(canvas, resizedDetection)
          }, 100)
        })
      } catch (err) {
        console.log(err)
        if (err.message === 'Permission denied') {
          this.alertCustomError(
            'Camera Permission Denied',
            'Please allow camera permission.'
          )
        } else {
          this.alertCustomError(
            'Unexpected Error',
            `Something happen when try to activate camera. ${err}`
          )
        }
      }
    },
    cancelImage() {
      this.imageData.image = null
      this.result = ''
      this.showCameraModal = true
      this.playVideo()
    },
    alertCustomError(title, message) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'close',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
  },
  async mounted() {
    await Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('/models'),
    ])

    this.isLoadingCamera = true
    try {
      await this.playVideo()
    } catch (err) {
      this.isLoadingCamera = false
      this.$emit('close')
    }
    this.isLoadingCamera = false
  },
}
</script>

<style scoped>
canvas {
  position: absolute;
}
</style>
