<template>
  <div
    v-if="isLoading"
    class="loading-container is-flex is-justify-content-center"
  >
    <div class="flex-loading is-flex is-justify-content-center">
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
}
</script>
