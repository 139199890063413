<template>
  <div class="columns is-multiline is-centered">
    <div
      class="column is-3 pt-0 pl-0"
      v-for="(item, index) in data"
      :key="index"
    >
      <template>
        <r-card>
          <div
            class="is-12 has-text-centered rise-attendance__my-attendance-title"
          >
            {{ item && item.date ? formatDate(item.date) : formatDate(item) }}
          </div>
          <hr />
          <div class="columns is-multiline is-justify-content-center">
            <div class="is-2">
              <b-icon
                icon="arrow-right"
                size="is-small"
                type="is-success"
              ></b-icon>
            </div>
            <div class="is-10 rise-attendance__my-attendance-clock-in">
              {{
                item && item.firstCheckIn ? formatTime(item.firstCheckIn) : '-'
              }}
            </div>
          </div>
          <div class="columns is-multiline is-justify-content-center">
            <div class="is-2">
              <b-icon
                icon="arrow-left"
                size="is-small"
                type="is-danger"
              ></b-icon>
            </div>
            <div class="is-10 rise-attendance__my-attendance-clock-out">
              {{
                item && item.lastCheckOut ? formatTime(item.lastCheckOut) : '-'
              }}
            </div>
          </div>
          <hr />
          <div
            class="is-12 rise-attendance__my-attendance-duration has-text-centered"
          >
            {{ item && item.durationPerDay ? item.durationPerDay : '-' }}
          </div>
        </r-card>
      </template>
      <template slot="empty">No data found</template>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone'
export default {
  props: ['data'],

  methods: {
    formatDate(str) {
      const d = new Date(str)
      return moment(d).format('D MMM YYYY')
    },
    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },
  },
}
</script>
