<template>
  <r-card>
    <div class="rise-card-title">Calendar</div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Personal">
        <r-card class="calendar-outer-card">
          <r-card class="calendar-inner-card">
            <FullCalendar
              :options="calendarPersonal"
              ref="riseCalendar"
              class="rise-calendar"
            >
              <!-- header -->
              <template v-slot:dayHeaderContent="arg">
                <p class="calendar-header-date">
                  {{ formatHeaderDay(arg.date) }}
                </p>
              </template>
              <!-- cell -->
              <template v-slot:dayCellContent="arg">
                <p>
                  {{ formatCellContent(arg.date) }}
                </p>
                <p class="calendar-todays-day" v-if="isDateToday(arg.date)">
                  {{ getDaysName(arg.date) }}
                </p>
              </template>
              <!-- event dots -->
              <template v-slot:eventContent="arg">
                <div
                  class="calendar-dot"
                  :class="
                    arg.event.extendedProps.name
                      ? 'events-bg-team'
                      : 'events-bg-personal'
                  "
                />
              </template>
            </FullCalendar>
          </r-card>
          <!-- events details -->
          <div class="calendar-upcoming-container">
            <h3 class="calendar-upcoming-title">Upcoming</h3>
            <div
              class="calendar-events-container"
              v-if="calendarSelectedPersonal && calendarSelectedPersonal.agenda"
            >
              <div
                class="calendar-events-personal"
                v-for="(item, index) in calendarSelectedPersonal.agenda"
                :key="index"
              >
                <div class="events-detail-wrapper">
                  <div
                    class="events-dot"
                    :class="item.name ? 'events-bg-team' : 'events-bg-personal'"
                  />
                  <div class="events-detail">
                    <p class="events-title">
                      {{
                        `${item.requestReason} - ${
                          item.requestType === 'timeoff'
                            ? 'Time Off'
                            : 'Change Schedule'
                        }`
                      }}
                    </p>
                    <p
                      class="events-date"
                      v-if="item.requestType === 'timeoff'"
                    >
                      {{ displayEventsDate(item.startDate, item.endDate) }}
                    </p>
                    <p class="events-date" v-else>
                      {{
                        displayChangeScheduleDate(item.date, item.changeDate)
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="events-time"
                  :class="
                    item.name ? 'events-time-team' : 'events-time-personal'
                  "
                  v-if="item.time && item.time !== '00:00:00'"
                >
                  {{ item.time }}
                </div>
              </div>
            </div>
            <div v-else>There is no upcoming agenda</div>
          </div>
        </r-card>
      </b-tab-item>
      <b-tab-item label="Teams">
        <r-card class="calendar-outer-card">
          <r-card class="calendar-inner-card">
            <FullCalendar
              :options="calendarTeam"
              ref="riseCalendar"
              class="rise-calendar"
            >
              <!-- header -->
              <template v-slot:dayHeaderContent="arg">
                <p class="calendar-header-date">
                  {{ formatHeaderDay(arg.date) }}
                </p>
              </template>
              <!-- cell -->
              <template v-slot:dayCellContent="arg">
                <p>
                  {{ formatCellContent(arg.date) }}
                </p>
                <p class="calendar-todays-day" v-if="isDateToday(arg.date)">
                  {{ getDaysName(arg.date) }}
                </p>
              </template>
              <!-- event dots -->
              <template v-slot:eventContent="arg">
                <div
                  class="calendar-dot"
                  :class="
                    arg.event.extendedProps.name
                      ? 'events-bg-team'
                      : 'events-bg-personal'
                  "
                />
              </template>
            </FullCalendar>
          </r-card>
          <!-- events details -->
          <div class="calendar-upcoming-container">
            <h3 class="calendar-upcoming-title">Upcoming</h3>
            <div
              class="calendar-events-container"
              v-if="calendarSelectedTeam && calendarSelectedTeam.length > 0"
            >
              <div v-for="(item, index) in calendarSelectedTeam" :key="index">
                <div class="calendar-events-teams" v-if="item.agenda != null">
                  <div class="events-detail-wrapper">
                    <div
                      class="events-dot"
                      :class="
                        item.fullName ? 'events-bg-team' : 'events-bg-personal'
                      "
                    />
                    <div class="events-detail">
                      <p class="events-title">
                        {{ item.fullName }}
                      </p>
                      <div>
                        <div
                          v-for="(agenda, agendaIndex) in item.agenda"
                          :key="agendaIndex"
                        >
                          <p class="is-capitalized">
                            {{
                              `${agenda.requestReason} - ${
                                agenda.requestType === 'timeoff'
                                  ? 'Time Off'
                                  : 'Change Schedule'
                              }`
                            }}
                          </p>
                          <p
                            class="events-date"
                            v-if="agenda.requestType === 'timeoff'"
                          >
                            {{
                              displayEventsDate(
                                agenda.startDate,
                                agenda.endDate
                              )
                            }}
                          </p>
                          <p class="events-date" v-else>
                            {{
                              displayChangeScheduleDate(
                                agenda.date,
                                agenda.changeDate
                              )
                            }}
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>No upcoming agenda</div>
          </div>
        </r-card>
      </b-tab-item>
    </b-tabs>
  </r-card>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment-timezone'
import ApiService from '../services/common/api.service'
import { mapActions } from 'vuex'

export default {
  components: {
    FullCalendar,
  },
  watch: {
    async activeMonthPersonal(value) {
      await this.getAllTimeOffs(value).then((result) => {
        this.tempDataTimeOff = result
      })
      this.mapDataTimeOff()
    },
    async activeMonthTeam(value) {
      await this.getAllTimeOffs(value).then((result) => {
        this.tempDataTimeOff = result
      })
      this.mapDataTimeOff()
    },
  },
  data() {
    return {
      activeTab: 0,

      // calendar time oft
      dateStrNow: moment().utc(new Date()).local().format('YYYY-MM-DD'),
      tempDataTimeOff: [],
      dataTimeOffForUpcomingEvents: [],
      activeMonthPersonal: null,
      activeMonthTeam: null,

      // API methods for FullCalendar
      calendarTeam: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        showNonCurrentDates: false,
        eventDisplay: 'list-item',
        selectable: true,
        // // method that will be triggered when user change months
        datesSet: (dateInfo) => {
          const month = moment(dateInfo.startStr).format('M')
          const year = moment(dateInfo.startStr).format('YYYY')
          const activeMonth = [+month, +year]
          if (
            JSON.stringify(this.activeMonthTeam) !== JSON.stringify(activeMonth)
          ) {
            this.activeMonthTeam = [+month, +year]
          }
        },
        dayHeaderClassNames: 'rise-calendar-header',
        dayCellClassNames: 'rise-calendar-cell',
        dateClick: (info) => {
          this.dateClickTeam(info.dateStr)
        },

        eventColor: '#B100FF',
        events: [],
      },

      calendarPersonal: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        showNonCurrentDates: false,
        eventDisplay: 'list-item',
        selectable: true,
        // // method that will be triggered when user change months
        datesSet: (dateInfo) => {
          const month = moment(dateInfo.startStr).format('M')
          const year = moment(dateInfo.startStr).format('YYYY')
          const activeMonth = [+month, +year]
          if (
            JSON.stringify(this.activeMonthPersonal) !==
            JSON.stringify(activeMonth)
          ) {
            this.activeMonthPersonal = [+month, +year]
          }
        },
        dayHeaderClassNames: 'rise-calendar-header',
        dayCellClassNames: 'rise-calendar-cell',
        dateClick: (info) => {
          this.dateClickPersonal(info.dateStr)
        },

        eventColor: '#B100FF',
        events: [],
      },

      calendarSelectedPersonal: null,
      calendarSelectedTeam: null,
    }
  },
  methods: {
    ...mapActions({
      getAllTimeOffs: 'timeOffModule/getAllTimeOffs',
    }),
    // to check whether the date is today
    isDateToday(date) {
      if (moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
        return true
      } else {
        return false
      }
    },

    displayEventsDate(start, end) {
      if (end && start !== end) {
        const startsMonth = moment(start).format('MM')
        const endsMonth = moment(end).format('MM')

        if (startsMonth === endsMonth) {
          return `${moment(start).format('DD')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        } else {
          return `${moment(start).format('DD MMM YYYY')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        }
      }
      return moment(start).format('D MMMM YYYY')
    },

    displayChangeScheduleDate(start, end) {
      if (end && start !== end) {
        const startsMonth = moment(start).format('MM')
        const endsMonth = moment(end).format('MM')

        if (startsMonth === endsMonth) {
          return `${moment(start).format('DD MMM')} to ${moment(end).format(
            'DD MMM YYYY'
          )}`
        } else {
          return `${moment(start).format('DD MMM YYYY')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        }
      }
      return moment(start).format('D MMMM YYYY')
    },

    mapDataTimeOff() {
      const myTimeOff = this.tempDataTimeOff.events.map((el) => ({
        title: el.eventName,
        start: moment.utc(el.startDate).local().format('YYYY-MM-DD'),
        end: moment.utc(el.endDate).local().format('YYYY-MM-DD'),
        type: el.type,
        time: el.time,
      }))

      //filter this.tempDataTimeOff.teamEvents to get only approved status
      this.tempDataTimeOff.teamEvents = this.tempDataTimeOff.teamEvents.filter(
        (el) => el?.status?.toLowerCase() == 'approved'
      )

      let teamTimeOff = []
      if (this.tempDataTimeOff.teamEvents.length > 0) {
        teamTimeOff = this.tempDataTimeOff.teamEvents.map((el) => ({
          title: el.eventName,
          start: moment.utc(el.startDate).local().format('YYYY-MM-DD'),
          end: moment.utc(el.endDate).local().format('YYYY-MM-DD'),
          type: el.type,
          name: el.firstName,
          time: el.time,
        }))
      }

      this.dataTimeOffForUpcomingEvents = [...myTimeOff, ...teamTimeOff]

      // filtering events which have the same starting date to display on calendar
      let tempMyTimeOff = myTimeOff.filter(
        (el, index, self) =>
          index === self.findIndex((t) => t.start === el.start)
      )
      let tempTeamTimeOff = teamTimeOff.filter(
        (el, index, self) =>
          index === self.findIndex((t) => t.start === el.start)
      )

      this.calendarTeam.events = [...tempTeamTimeOff]
      this.calendarPersonal.events = [...tempMyTimeOff]
    },
    // format header calendar
    formatHeaderDay(date) {
      return moment(date).format('dddd').slice(0, 1)
    },

    // format cell calendar
    formatCellContent(date) {
      return moment(date).format('D')
    },

    // get day's name
    getDaysName(date) {
      return moment(date).format('ddd')
    },

    async dateClickTeam(info) {
      let selectedCalendar = info
      let response = await ApiService.get(
        `/api/space-roketin/user/agenda/my-team?date=${selectedCalendar}`
      )

      this.calendarSelectedTeam = response.data.data
    },

    async dateClickPersonal(info) {
      let selectedCalendar = info
      let response = await ApiService.get(
        `/api/space-roketin/user/agenda/personal?date=${selectedCalendar}`
      )

      this.calendarSelectedPersonal = response.data.data
    },
  },
  async mounted() {
    await this.dateClickTeam(this.dateStrNow)
    await this.dateClickPersonal(this.dateStrNow)
    // get time off data for calendar
    const month = moment().format('M')
    const year = moment().format('YYYY')
    await this.getAllTimeOffs([+month, +year]).then((result) => {
      this.tempDataTimeOff = result
    })
    this.mapDataTimeOff()
  },
  //
}
</script>

<style scoped></style>
