<template>
  <r-page page-title="Rise" :key="key">
    <template #page-header>
      <h2 class="page-title">Rise</h2>
    </template>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-8">
          <div class="column is-12 pt-0 pl-0 pr-0">
            <announcement-rise
              :published="announcementPublished"
            ></announcement-rise>
          </div>
        </div>
        <div class="column is-4">
          <div class="column is-12">
            <quote-component :user="user" :quotes="quoteRise"></quote-component>
          </div>
          <div class="column is-12">
            <r-card
              class="primary-bg-card dashboard-header p-0"
              style="
                background-image: url('/images/asset_rise_2.jpg');
                background-size: cover;
                background-repeat: no-repeat;
              "
            >
              <div class="columns is-vcentered dashboard-header__card">
                <div class="column is-6">
                  <div
                    class="columns is-align-content-flex is-flex-direction-column is-multiline is-justify-content-center"
                  >
                    <div
                      class="column is-narrow primary-card-work-status-detail p-0"
                    >
                      <h3
                        class="is-size-5 has-text-white is-align-self-flex mb-0"
                      >
                        {{ date }}
                      </h3>
                    </div>
                    <div
                      class="column is-narrow primary-card-work-status-detail p-0"
                    >
                      <h1
                        class="is-size-1 has-text-weight-black has-text-white is-align-self-flex mt-0 mb-0"
                      >
                        {{ timer ? timer : '00:00:00' }}
                      </h1>
                    </div>
                    <div
                      class="column is-narrow primary-card-work-status-detail p-0"
                    >
                      <div
                        class="subtitle is-size-5 is-align-self-flex has-text-white is-white has-text-weight-semibold mt-0"
                      >
                        {{
                          user && user.workingStatus === 'offline'
                            ? 'Stop Working'
                            : 'Working'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div v-if="isLoading">
                    <Loading :is-loading="isLoading" />
                  </div>
                  <div v-else class="is-justify-content-flex-end is-flex">
                    <div
                      v-if="user && user.workingStatus === 'online'"
                      @click="toggleWorking"
                      class="pointer-click"
                    >
                      <img
                        src="/images/check-out.png"
                        class="is-align-self-flex-end clock-in-button"
                        alt="pause-circle"
                      />
                    </div>
                    <div
                      v-if="user && user.workingStatus === 'offline'"
                      @click="clockInValidation"
                      class="pointer-click"
                    >
                      <img
                        src="/images/check-in.png"
                        class="is-align-self-flex-end clock-in-button"
                        alt="play-circle"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </r-card>
          </div>
        </div>
      </div>

      <!-- section attendance card -->
      <div class="columns is-multiline">
        <div class="column is-5 pt-0">
          <div class="column is-12 pl-0 pr-0 pb-0">
            <r-card>
              <div class="level">
                <div class="level-left rise-card-title">Latest Attendance</div>
                <router-link to="attendance" class="is-link">
                  See All
                </router-link>
              </div>
              <my-attendance :data="dataMyAttendance"></my-attendance>
            </r-card>
          </div>
          <div class="column is-12 pl-0 pr-0">
            <r-card>
              <div class="rise-card-title mb-4">My Teams Attendance</div>
              <b-table
                :data="dataTeamAttendance"
                class="rise-table-attendance"
                :sticky-header="stickyHeaders"
                height="196px"
              >
                <b-table-column
                  field="name"
                  label="Name"
                  cell-class="rise-table-column-date"
                  v-slot="props"
                >
                  {{
                    props.row.lastName
                      ? `${props.row.firstName} ${props.row.lastName}`
                      : props.row.firstName
                  }}
                </b-table-column>

                <b-table-column
                  field="status"
                  label="Status"
                  centered
                  v-slot="props"
                >
                  <span
                    class="attendance-status-wfo"
                    :class="
                      props && props.row.isRemote
                        ? 'attendance-status-wfh'
                        : 'attendance-status-wfo'
                    "
                  >
                    {{ props.row.isRemote ? 'WFH' : 'WFO' }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="clock_in"
                  label="Clock In"
                  centered
                  v-slot="props"
                >
                  {{
                    props.row.checkInTime
                      ? formatTime(props.row.checkInTime)
                      : '-'
                  }}
                </b-table-column>

                <b-table-column
                  field="clock_out"
                  label="Clock Out"
                  centered
                  v-slot="props"
                >
                  {{
                    props.row.checkOutTime
                      ? formatTime(props.row.checkOutTime)
                      : '-'
                  }}
                </b-table-column>

                <template #empty>
                  <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </r-card>
          </div>
        </div>
        <!-- Calendar -->
        <div class="column is-3 rise-calendar-card">
          <PersonalAndTeamCalendar />
        </div>
        <div class="column is-4 pl-5 pr-5">
          <r-card>
            <div class="rise-card-title pb-4">Today's Activity</div>
            <div class="columns is-multiline">
              <div class="column is-9">
                <div
                  class="is-12 has-text-weight-bold mb-0 activity-attendance-timer"
                >
                  {{
                    activityAttendanceTimer
                      ? activityAttendanceTimer
                      : '00:00:00'
                  }}
                </div>
                <div class="is-12 mt-0 activity-attendance-location">
                  <b-icon icon="map-marker" custom-size="mdi-18px"></b-icon>
                  <span class="has-text-centered">
                    {{ activeActivityLocation }}
                  </span>
                </div>
              </div>
              <div class="column is-3">
                <b-button
                  tag="button"
                  class="is-command center"
                  icon-left="plus"
                  expanded
                  @click="openModalActivityAttendance"
                >
                  Add Activity
                </b-button>
              </div>
              <div class="column is-12 mt-0 mb-0 pt-0">
                <hr class="black-divider" />
              </div>
              <div class="column is-12 mt-0 pt-0">
                <b-table
                  :data="dataActivityAttendance"
                  class="rise-table-attendance"
                  height="388px"
                  overflow="auto"
                  :sticky-header="true"
                >
                  <b-table-column
                    field="description"
                    label="Description"
                    cell-class="rise-table-column-activity-date"
                    v-slot="props"
                  >
                    {{
                      props.row.attendanceActivityDescription
                        ? props.row.attendanceActivityDescription
                        : '-'
                    }}
                  </b-table-column>

                  <b-table-column
                    field="activity_duration"
                    label="Activity Duration"
                    centered
                    cell-class="rise-table-column-activity-duration"
                    v-slot="props"
                  >
                    {{
                      props.row.activityDuration
                        ? formatDurationSecond(props.row.activityDurationSecond)
                        : '-'
                    }}
                  </b-table-column>
                  <b-table-column
                    field="action"
                    label="Action"
                    centered
                    v-slot="props"
                  >
                    <b-button
                      :icon-left="props.row.isPlayable ? 'play' : 'pause'"
                      type="is-command"
                      :loading="props.row.isLoading"
                      @click.native="
                        toggleActivity(
                          props.row.isPlayable ? 'play' : 'pause',
                          props.row,
                          props.index
                        )
                      "
                    ></b-button>
                  </b-table-column>

                  <template #empty>
                    <div class="has-text-centered">No records</div>
                  </template>
                </b-table>
              </div>
            </div>
          </r-card>
          <r-page-modal
            :prompt-on-leave="false"
            prompt-message="Are you sure?"
            v-if="isModalActivityAttendanceOpen"
            @hide="closeModalActivityAttendance"
            :is-modal="true"
            key="modalKey"
            class="modal-allowed-capacity"
            remove-scrolling
          >
            <template #page-content>
              <h3 class="modal-title has-text-weight-black">
                Add New Activity
                <span class="office-pointer-click is-pulled-right">
                  <b-icon
                    icon="close"
                    size="is-small"
                    @click.native="closeModalActivityAttendance"
                  ></b-icon>
                </span>
              </h3>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form
                  @submit.prevent="handleSubmit(submit)"
                  class="is-labe-14px-normal form-timeoff-type"
                >
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <ValidationProvider
                        name="activity type"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          label="Activity Type"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-select
                            v-model="selectedAttendanceType"
                            expanded
                            placeholder="Select Attendance Type"
                            class="hover"
                          >
                            <option
                              v-for="option in activityAttendanceType"
                              :value="option.id"
                              :key="option.id"
                            >
                              {{ option.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-12">
                      <ValidationProvider
                        name="activity description"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          label="Activity Type"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            v-model="description"
                            expanded
                            placeholder="Reason"
                            type="textarea"
                            class="hover"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column is-12">
                      <b-button
                        expanded
                        native-type="submit"
                        type="is-primary"
                        class="has-text-weight-bold"
                        :loading="isActivityAttendanceRiseLoading"
                      >
                        Add
                      </b-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </template>
          </r-page-modal>
        </div>
      </div>

      <b-modal
        v-if="user && isFaceRegMandatory"
        @close="closeModalFaceCam"
        :active="isModalFaceCamOpen"
        trap-focus
        has-modal-card
      >
        <template #default="props">
          <FaceCompare
            @result="compareAndClockIn"
            @close="props.close"
            :img-url="user.faceRegFileUrl"
            :is-close-face-cam="isCloseFaceCam"
            @failed="handleFailedCompare"
          />
        </template>
      </b-modal>
    </template>
  </r-page>
</template>

<script>
import moment from 'moment-timezone'
import Loading from '@/components/Loading'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ApiService from '../services/common/api.service'
import { showToast } from '../services/util'
import AnnouncementRise from '@/components/AnnouncementRise.vue'
import MyAttendance from '@/components/MyAttendance.vue'
import QuoteComponent from '@/components/QuoteComponent.vue'
import FaceCompare from '../components/FaceCompare'
import PersonalAndTeamCalendar from '../components/PersonalAndTeamCalendar'

export default {
  components: {
    PersonalAndTeamCalendar,
    FaceCompare,
    Loading,
    AnnouncementRise,
    MyAttendance,
    QuoteComponent,
  },

  data() {
    return {
      key: 1, // for force render r-page
      date: null,
      isLoading: false,
      duration: null,
      working: false,
      pause: false,
      workTime: 0,
      announcements: [],
      timerCount: 0,
      activityTimerCount: 0,
      activityTimerId: -1,

      timer: null,
      activityAttendanceTimer: null,
      dataMyAttendance: [],
      dataActivityAttendance: [],
      tempDataMyAttendance: [],
      dataTeamAttendance: [],
      stickyHeaders: true,
      isModalActivityAttendanceOpen: false,
      isActivityPlayable: null,
      selectedAttendanceType: null,
      activityAttendanceType: [],
      activeActivityLocation: null,
      isPlayable: null,
      description: null,
      isSubmitting: false,
      isActivityAttendanceRiseLoading: false,
      startActivityTime: null,
      endActivityTime: null,
      firstStartActivityTime: null,
      announcementPublished: [],
      quoteRise: null,

      // for face recognition :
      isErrorFetchFaceRegMandatory: false,
      isFaceRegMandatory: false,
      isModalFaceCamOpen: false,
      faceRegCompareResult: false,
      isCloseFaceCam: false,
    }
  },
  async mounted() {
    // document.title = 'rise - Rise by Roketin'
    this.date = moment().format('dddd, DD MMMM YYYY')
    const formattedDate = moment().format('YYYY-MM-DD')
    this.setActivityAttendanceRise([])
    this.setAnnouncement([])

    await this.fetchFaceRecogMandatory()
    await this.loadAnnouncement()
    await this.loadQuoteRise()
    await this.loadActivityAttendance()
    await this.getActivityAttendanceType()
    await this.initTimer(formattedDate)
    await this.initActivityTimer()

    // get my attendance history
    const dateArray = new Array(4)
      .fill(1)
      .map((_, i) => moment().subtract(i, 'days').format('YYYY-MM-DD'))
    const startDate = dateArray[3]

    let param = {
      startDate: startDate,
      endDate: formattedDate,
    }

    await this.fetchAttendance(param).then((result) => {
      this.tempDataMyAttendance = result
    })
    await this.fetchMyTeamAttendance().then((result) => {
      this.dataTeamAttendance = result
    })
    dateArray.forEach((el, i) => {
      const filteredArray = this.tempDataMyAttendance.filter(
        (elem) => elem.date === el
      )
      if (filteredArray.length > 0) {
        dateArray[i] = filteredArray[0]
      }
    })
    this.dataMyAttendance = dateArray
  },
  methods: {
    ...mapActions({
      actionClockIn: 'user/checkIn',
      actionClockOut: 'user/checkOut',
      workDuration: 'user/getWorkDuration',
      totalActivityDurationPerDay:
        'activityAttendance/getTotalActivityDuration',
      fetchAttendance: 'attendance/fetchAttendance',
      fetchMyTeamAttendance: 'attendance/fetchMyTeamAttendance',
      fetchActivityAttendanceRise:
        'activityAttendance/fetchActivityAttendanceRise',
      fetchAnnouncementPublished: 'announcement/fetchAnnouncementPublished',
      fetchQuoteRise: 'quotes/fetchQuoteRise',
    }),

    ...mapMutations({
      setWorkingStatus: 'user/setWorkingStatus',
      setIsActiveInOtherCompany: 'user/setIsActiveInOtherCompany',
      setActivityAttendanceRise: 'activityAttendance/setActivityAttendanceRise',
      setAnnouncement: 'announcement/setAnnouncementPublished',
      setQuoteRise: 'quotes/setQuoteRise',
    }),
    handleFailedCompare(statusCode) {
      this.closeModalFaceCam()
      this.alertCustomError(
        'Failed to Compare Image',
        `Please try to recapture image from settings > general setting. (status code : ${statusCode})`
      )
    },
    async compareAndClockIn(result) {
      if (result) {
        let location = null
        try {
          location = await this.getLocation()
        } catch (e) {
          this.alertCustomError(
            'Failed to get location.',
            `Please allow geolocation permission in your browser settings. ${e.message}`
          )
        }
        if (location) {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude
          await this.clockIn(latitude, longitude)
          showToast('Clock in success', 'is-success', 'is-bottom')
        }
      } else {
        // show dialog alert
        this.alertCustomError('Face not match', 'Face not match')
      }
      this.closeModalFaceCam()
    },
    openModalFaceCam() {
      this.isCloseFaceCam = false
      this.isModalFaceCamOpen = true
    },
    closeModalFaceCam() {
      this.isCloseFaceCam = true
      this.isModalFaceCamOpen = false
    },

    async clockIn(latitude, longitude) {
      let data = {
        locationLatIn: latitude,
        locationLongIn: longitude,
      }
      this.setWorkingStatus('online')

      await this.actionClockIn(data)
        .then(() => {
          this.timerCount += 0.01
        })
        .catch((err) => {
          console.log(err)
          // back to offline state
          this.setWorkingStatus('offline')
          if (err.response.data.meta.message) {
            this.alertCustomError(
              'Clock In Failed.',
              err.response.data.meta.message
            )
          } else {
            this.alertCustomError(
              'Clock In Failed',
              'Unexpected error happened. Please look at the console'
            )
            // force render
            this.key++
          }
        })
    },
    async clockOut(latitude, longitude) {
      this.setWorkingStatus('offline')
      let data = {
        locationLatOut: latitude,
        locationLongOut: longitude,
      }
      await this.actionClockOut(data)
        .then(async () => {
          const formattedDate = moment().format('YYYY-MM-DD')

          await this.initTimer(formattedDate)
        })
        .catch((err) => {
          console.log(err)

          // back to online state
          this.setWorkingStatus('online')
          if (err?.response?.data?.meta?.message) {
            this.alertCustomError(
              'Clock Out Failed.',
              err?.response?.data?.meta?.message
            )
          } else {
            this.alertCustomError(
              'Clock Out Failed',
              'Unexpected error happened. Please look at the console'
            )
            // force refresh
            this.key++
          }
        })
    },
    openModalActivityAttendance() {
      this.isModalActivityAttendanceOpen = true
    },

    closeModalActivityAttendance() {
      this.isModalActivityAttendanceOpen = false
    },

    clockInValidation() {
      if (this.user.isActiveInOtherCompany) {
        this.confirmClockIn()
      } else {
        this.toggleWorking()
      }
    },
    confirmClockIn() {
      this.$buefy.dialog.confirm({
        title: 'Clock In Confirmation',
        message: `You still have active session in another company,
        are you sure you will clock in at this company ?
        Clock in on this company will automatically <b>turn off </b> sessions on other companies.`,
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        type: 'is-primary',
        onConfirm: () => this.toggleWorking(),
      })
    },

    async loadAnnouncement() {
      try {
        const response = await this.fetchAnnouncementPublished({
          perPage: 10,
          page: 1,
          sortField: 'id',
          sortOrder: 'desc',
        })
        this.announcementPublished = response.data.data
      } catch (e) {
        console.log(e)
      }
    },

    async loadQuoteRise() {
      try {
        const response = await this.fetchQuoteRise()
        this.quoteRise = response.data.data
      } catch (e) {
        console.log(e)
      }
    },

    closeModalAnnouncementDetail() {
      this.isOpenModalAnnouncementDetail = false
    },

    async loadActivityAttendance() {
      this.isActivityAttendanceRiseLoading = true
      try {
        const response = await this.fetchActivityAttendanceRise()
        if (response.data.length > 0) {
          this.dataActivityAttendance = response.data[0].data
          this.activityAttendanceTimer =
            response.data[0].totalActivityDurationPerDay
          this.isActivityPlayable = response.data[0].isActivityPlayable === 1

          this.activeActivityLocation = response.data[0].activeActivityLocation
            ? response.data[0].activeActivityLocation
            : '-'
          this.startActivityTime = response.data[0].startTime
          this.endActivityTime = response.data[0].endTime
          this.firstStartActivityTime = response.data[0].firstStartTime
        }
      } catch (e) {
        console.log(e)
      }
      this.isActivityAttendanceRiseLoading = false
    },

    async getActivityAttendanceType() {
      try {
        let response = await ApiService.get('/api/space-roketin/activity/type')
        this.activityAttendanceType = response.data
      } catch (e) {
        console.log(e)
      }
    },

    formatDurationSecond(durationSecond) {
      let hour = Math.floor(durationSecond / 3600)
      let min = Math.floor((durationSecond % 3600) / 60)
      let second = Math.floor((durationSecond % 3600) % 60)

      return `${hour}h ${min}m ${second}s`
    },

    handleEvents(events) {
      this.currentEvents = events
    },

    parseTime(isoDateString) {
      return moment.utc(isoDateString).local().format('DD MMM YYYY')
    },

    getRunningAttendanceActivityTimer(duration, start, end) {
      if (!start || !end || !duration) {
        throw Error(
          'either parameter duration, start, end cannot be null or nan in function getRunningAttendanceActivityTimer('
        )
      }
      const arr = duration && duration.split(':')
      let seconds = 0
      seconds += parseInt(arr && arr[0]) * 3600
      seconds += parseInt(arr && arr[1]) * 60
      seconds += parseInt(arr && arr[2])

      if (!this.isActivityPlayable) {
        const difference = (end - start) / 1000
        this.activityTimerCount = Math.floor(difference + seconds)
      } else {
        this.activityTimerCount = Math.floor(seconds)
      }
    },

    async getActivityInParameter(activityTypeId, description) {
      this.isSubmitting = true
      let location = null
      try {
        location = await this.getLocation()
      } catch (e) {
        this.alertCustomError(
          'Failed to get location.',
          `Please allow geolocation in your browser settings. ${e.message}`
        )
      }

      if (location) {
        this.isActivityAttendanceRiseLoading = true
        try {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude

          if (this.user && this.user.workingStatus === 'online') {
            let locationLatIn = latitude
            let locationLongIn = longitude
            let form = new FormData()

            form.append('activity_type_id', activityTypeId)
            form.append('location_lat_in', locationLatIn)
            form.append('location_long_in', locationLongIn)
            form.append('description', description)

            await ApiService.post(
              '/api/space-roketin/attendance-activity/in',
              form,
              true
            )

            this.selectedAttendanceType = null
            this.description = null
            this.isActivityPlayable = false
            await this.loadActivityAttendance()
            await this.initActivityTimer()

            this.closeModalActivityAttendance()
          } else {
            showToast(
              'You must clock in first before creating activity',
              'is-danger',
              'is-bottom'
            )
          }
        } catch (e) {
          console.log(e)
        }

        this.isActivityAttendanceRiseLoading = false
      }

      this.isSubmitting = false
    },

    async submit() {
      // find another activity which still playing and stop it!
      let indexOtherActivity = this.dataActivityAttendance.findIndex((el) => {
        return el.isPlayable === 0
      })
      if (indexOtherActivity > -1) {
        await this.pauseActivity(indexOtherActivity)
      }

      await this.getActivityInParameter(
        this.selectedAttendanceType,
        this.description
      )
    },

    async resumeActivity(activity, index) {
      // tembak api play
      this.dataActivityAttendance[index].isLoading = true
      try {
        await this.getActivityInParameter(
          activity.attendanceActivityType.id,
          activity.attendanceActivityDescription
        )

        if (this.user && this.user.workingStatus === 'online') {
          // ubah state punya activity tersebut is playable jadi false (agar icon berubah jadi pause)
          let temp = [...this.dataActivityAttendance]

          // set all to pause
          temp.forEach((t) => (t.isPlayable = 1))

          // set that activity to play
          temp[index].isPlayable = 0
          this.dataActivityAttendance = [...temp]

          this.isActivityPlayable = false
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.dataActivityAttendance[index].isLoading = false
    },

    async pauseActivity(index) {
      // tembak api pause
      let location = null
      try {
        location = await this.getLocation()
      } catch (e) {
        this.alertCustomError(
          'Failed to get location.',
          `Please allow geolocation in your browser settings. ${e.message}`
        )
      }
      if (location) {
        try {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude

          let locationLatOut = latitude
          let locationLongOut = longitude

          let form = new FormData()

          form.append('location_lat_out', locationLatOut)
          form.append('location_long_out', locationLongOut)

          await ApiService.post(
            '/api/space-roketin/attendance-activity/out',
            form,
            true
          )

          locationLatOut = null
          locationLongOut = null
          await this.loadActivityAttendance()

          // ubah state punya activity tersebut is playable jadi true supaya icon beruabh kembali jadi play
          let temp = [...this.dataActivityAttendance]

          temp[index].isPlayable = 1
          // ubah state acitivty is playable jadi true supaya tombolnya enabled semua
          this.isActivityPlayable = true
        } catch (e) {
          console.log(e)
        }
      }
    },

    async toggleActivity(action, activity, index) {
      if (action === 'play') {
        // find another activity which still playing and stop it!
        let indexOtherActivity = this.dataActivityAttendance.findIndex(
          (el, i) => {
            return i !== index && el.isPlayable === 0
          }
        )
        if (indexOtherActivity > -1) {
          await this.pauseActivity(indexOtherActivity)
        }

        await this.resumeActivity(activity, index)
      } else {
        await this.pauseActivity(index)
      }
    },

    getRunningTimer(duration, start, end) {
      const arr = duration && duration.split(':')
      let seconds = 0
      seconds += parseInt(arr && arr[0]) * 3600
      seconds += parseInt(arr && arr[1]) * 60
      seconds += parseInt(arr && arr[2])

      if (this.user.workingStatus === 'online') {
        const difference = (end - start) / 1000
        this.timerCount = Math.floor(difference + seconds)
      } else {
        this.timerCount = Math.floor(seconds)
      }
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!('geolocation' in navigator)) {
          reject(new Error('Geolocation is not available.'))
        }
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos)
          },
          (err) => {
            reject(err)
          }
        )
      })
    },

    async initTimer(formattedDate) {
      // for work duration
      this.duration = await this.workDuration(formattedDate)
      this.timer = this.duration
      await this.$store.dispatch('user/loadUserFromToken')

      // get current work duration
      const latestAttendance = moment
        .utc(this.user.latestAttendance.startTime)
        .local()
        .valueOf()
      const currentUnixTime = moment().valueOf()
      this.getRunningTimer(this.duration, latestAttendance, currentUnixTime)
    },

    async initActivityTimer() {
      if (!this.activityDuration) {
        this.activityDuration = '00:00:00'
      } else {
        try {
          this.activityDuration = this.activityAttendanceTimer

          const latestActivityAttendance = moment
            .utc(this.startActivityTime)
            .local()
            .valueOf()

          const currentUnixActivityTime = moment().valueOf()
          this.getRunningAttendanceActivityTimer(
            this.activityDuration,
            latestActivityAttendance,
            currentUnixActivityTime
          )
        } catch (err) {
          console.log('init activity timer error : ', err)
        }
      }
    },

    async toggleWorking() {
      this.isLoading = true
      let location = null
      try {
        location = await this.getLocation()
      } catch (e) {
        this.alertCustomError(
          'Failed to get location.',
          `Please allow geolocation permission in your browser settings. ${e.message}`
        )
      }
      if (location) {
        try {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude

          if (this.user && this.user.workingStatus === 'online') {
            await this.clockOut(latitude, longitude)
          } else {
            if (this.isFaceRegMandatory) {
              this.openModalFaceCam()
            } else {
              await this.clockIn(latitude, longitude)
            }
          }
        } catch (e) {
          this.alertCustomError(
            'Clock in failed.',
            e.response && e.response.data.meta.message
          )
        }
      }

      this.isLoading = false
    },

    async fetchFaceRecogMandatory() {
      try {
        let response = await ApiService.get(
          '/api/space-roketin/settings/face-recognition-mandatory'
        )
        if (response.data[0]) {
          this.isFaceRegMandatory = !!response.data[0].isMandatory
        }
        this.isErrorFetchFaceRegMandatory = false
      } catch (err) {
        this.isErrorFetchFaceRegMandatory = true
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
    },

    alertCustomError(title, message) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'close',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    formatDate(str) {
      const d = new Date(str)
      return moment(d).format('D MMM')
    },
    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },
    trimEventName(title, name) {
      if (name) {
        return `${name}'s - ( ${title})`
      }
      return title
    },

    formatTimerActivity(value) {
      try {
        let hours = 0
        let minutes = 0
        hours = Math.floor(value / 3600)
        minutes = Math.floor((value % 3600) / 60)
        const seconds = Math.floor(value % 60)

        const _h = hours < 10 ? `0${hours}` : hours
        const _m = minutes < 10 ? `0${minutes}` : minutes
        const _s = seconds < 10 ? `0${seconds}` : seconds
        this.activityAttendanceTimer = `${_h}:${_m}:${_s}`
      } catch (err) {
        this.activityAttendanceTimer = `00:00:00`
        console.log('error while formatting timer activity ', err)
      }
    },
    startActivityTimer() {
      this.activityTimerId = setInterval(() => {
        this.activityTimerCount++
      }, 1000)
    },
    stopActivityTimer() {
      clearInterval(this.activityTimerId)
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/getUserInfo',
      activityAttendanceRise: 'activityAttendance/getActivityAttendanceRise',
      getAnnouncementPublished: 'announcement/getAnnouncementPublished',
      getQuoteRise: 'quotes/getQuoteRise',
    }),
    elapsedWorkTime() {
      const date = new Date(null)
      date.setSeconds(this.workTime / 1000)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(':') - 2, 8)
    },
    isStoppable() {
      return !this.working && this.pause
    },
    getUpcomingEvents() {
      const today = moment().format('YYYY-MM-DD')
      function compare(a, b) {
        if (a.start < b.start) {
          return -1
        }
        if (a.start > b.start) {
          return 1
        }
        return 0
      }
      const filtered = this.dataTimeOffForUpcomingEvents
        .filter(
          (el) =>
            new Date(el.start) >= new Date(today) ||
            new Date(el.end) >= new Date(today)
        )
        .sort(compare)

      return filtered
    },
  },
  watch: {
    timerCount(value) {
      if (this.user.workingStatus === 'online') {
        setTimeout(() => {
          this.timerCount++
        }, 1000)
        let hours = 0
        let minutes = 0
        hours = Math.floor(value / 3600)
        minutes = Math.floor((value % 3600) / 60)
        const seconds = Math.floor(value % 60)

        const _h = hours < 10 ? `0${hours}` : hours
        const _m = minutes < 10 ? `0${minutes}` : minutes
        const _s = seconds < 10 ? `0${seconds}` : seconds
        this.timer = `${_h}:${_m}:${_s}`
      }
    },

    isActivityPlayable(val) {
      if (!val) {
        this.startActivityTimer()
      } else {
        this.stopActivityTimer()
      }
    },

    activityTimerCount(value) {
      this.formatTimerActivity(value)
    },
  },
}
</script>
